import React from 'react';
import backgroundVideo from '../assets/background-video.mp4';

const Hero = () => {
  return (
    <section className="relative h-screen overflow-hidden">
      <div className="absolute inset-0">
        <video
          autoPlay
          loop
          muted
          playsInline
          className="w-full h-full object-cover"
        >
          <source src={backgroundVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
        <div className="text-center">
          <h2 className="text-5xl md:text-7xl font-serif text-white mb-4">
            Luxuriöse Patchwork-Quilts
          </h2>
          <p className="text-2xl md:text-3xl text-mint-200 mb-8">
            Handgemacht mit Liebe in Halle
          </p>
          <a
            href="/quilts"
            className="inline-flex items-center text-white bg-mint-600 px-6 py-3 rounded-full hover:bg-mint-700 transition-colors duration-300"
          >
            Meine Quilts
          </a>
        </div>
      </div>
    </section>
  );
};

export default Hero;