import React from 'react';
import aboutImage from '../assets/about.jpg';

const About = () => {
  return (
    <section id="about" className="py-24 bg-white">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-serif mb-12 text-center">Über Lappeteppe</h2>
        <div className="flex flex-col md:flex-row items-center gap-12">
          <div className="md:w-1/2">
            <img
              src={aboutImage}
              alt="Über Lappeteppe"
              className="w-full h-auto rounded-lg shadow-xl transform -rotate-2"
            />
          </div>
          <div className="md:w-1/2">
            <p className="text-lg leading-relaxed mb-6">
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
            </p>
            <p className="text-lg leading-relaxed mb-6">
            At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
            </p>
            <blockquote className="border-l-4 border-mint-500 pl-4 italic text-mint-700">
              "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam."
            </blockquote>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;