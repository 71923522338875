import React, { useState } from 'react';
import peoniesGarden from '../assets/quilts/peonies-garden.jpg';
import confettiDreams from '../assets/quilts/confetti-dreams.jpg';
import nordicStar from '../assets/quilts/nordic-star.jpg';

const quilts = [
  { id: 1, name: 'Peonies Garden', image: peoniesGarden, color: 'bg-sky-200', price: 2699 },
  { id: 2, name: 'Confetti Dreams', image: confettiDreams, color: 'bg-rose-200', price: 2899 },
  { id: 3, name: 'Nordic Star', image: nordicStar, color: 'bg-amber-200', price: 2999 },
];

const Products = () => {
  const [selectedQuilt, setSelectedQuilt] = useState(null);

  return (
    <section id="quilts" className="py-24 bg-mint-50">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-serif mb-12 text-center">Meine Quilts</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
          {quilts.map((quilt, index) => (
            <div
              key={quilt.id}
              className={`bg-white p-6 rounded-lg shadow-lg transform hover:-translate-y-2 transition-transform duration-300 ${
                index % 2 === 0 ? 'md:translate-y-8' : ''
              }`}
            >
              <div className="relative mb-6 overflow-hidden rounded-lg">
                <div className={`absolute inset-0 ${quilt.color} mix-blend-multiply`}></div>
                <img
                  src={quilt.image}
                  alt={quilt.name}
                  className="w-full h-80 object-cover transform hover:scale-105 transition-transform duration-300"
                />
              </div>
              <h3 className="text-2xl font-serif mb-2">{quilt.name}</h3>
              <p className="text-xl font-bold text-mint-600 mb-4">{quilt.price.toLocaleString()} €</p>
              <p className="text-mint-700 mb-6">
                Die {quilt.name.toLowerCase()}. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              </p>
              <button
                onClick={() => setSelectedQuilt(quilt)}
                className="bg-mint-500 text-white px-6 py-3 rounded-full hover:bg-mint-600 transition-colors duration-300 transform hover:scale-105"
              >
                Anfragen
              </button>
            </div>
          ))}
        </div>
        {/* Modal */}
        {selectedQuilt && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
            <div className="bg-white p-8 rounded-lg max-w-md w-full">
              <h3 className="text-2xl font-serif text-mint-900 mb-2">{selectedQuilt.name}</h3>
              <p className="text-xl font-bold text-mint-600 mb-6">${selectedQuilt.price.toLocaleString()}</p>
              <form>
                <div className="mb-4">
                  <label htmlFor="name" className="block text-mint-800 mb-2 font-semibold">Name</label>
                  <input
                    type="text"
                    id="name"
                    className="w-full px-4 py-3 border border-mint-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-mint-500 transition-all duration-300"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="email" className="block text-mint-800 mb-2 font-semibold">Email</label>
                  <input
                    type="email"
                    id="email"
                    className="w-full px-4 py-3 border border-mint-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-mint-500 transition-all duration-300"
                    required
                  />
                </div>
                <div className="mb-6">
                  <label htmlFor="message" className="block text-mint-800 mb-2 font-semibold">Nachricht</label>
                  <textarea
                    id="message"
                    rows={4}
                    className="w-full px-4 py-3 border border-mint-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-mint-500 transition-all duration-300"
                    required
                  ></textarea>
                </div>
                <div className="flex justify-end gap-4">
                  <button
                    type="button"
                    onClick={() => setSelectedQuilt(null)}
                    className="bg-mint-200 text-mint-800 px-6 py-3 rounded-full hover:bg-mint-300 transition-colors duration-300"
                  >
                    Abbrechen
                  </button>
                  <button
                    type="submit"
                    className="bg-mint-500 text-white px-6 py-3 rounded-full hover:bg-mint-600 transition-colors duration-300"
                  >
                    Senden
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default Products;