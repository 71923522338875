import React from 'react';

const Footer = () => {
  return (
    <div className="bg-mint-900 text-mint-100 py-12">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap justify-between items-center">
          <div className="w-full md:w-1/3 mb-6 md:mb-0">
            <h3 className="text-2xl font-serif mb-4">Lappeteppe</h3>
            <p className="text-mint-300">
              Luxuriöse Patchwork-Quilts von Lappeteppe
            </p>
          </div>
          <div className="w-full md:w-1/3 mb-6 md:mb-0">
            <h4 className="text-xl mb-4">
                Links
                </h4>
            <ul className="space-y-2">
              <li>
                <a
                  href="/about"
                  className="hover:text-white transition-colors duration-300"
                >
                  Über Lappeteppe
                </a>
              </li>
              <li>
                <a
                  href="/quilts"
                  className="hover:text-white transition-colors duration-300"
                >
                  Quilts
                </a>
              </li>
              <li>
                <a
                  href="/contact"
                  className="hover:text-white transition-colors duration-300"
                >
                  Kontakte
                </a>
              </li>
            </ul>
          </div>
          <div className="w-full md:w-1/3">
            <h4 className="text-xl mb-4">Social Media</h4>
            <div className="flex space-x-4">
              <a
                href="#"
                className="text-mint-300 hover:text-white transition-colors duration-300"
              >
                Instagram
              </a>
            </div>
          </div>
        </div>
        <div className="mt-8 pt-8 border-t border-mint-800 text-center">
          <p className="mb-4">&copy; 2023 Lappeteppe. Alle Rechte vorbehalten.</p>
          <div className="flex justify-center space-x-4">
            <a
              href="#"
              className="text-mint-300 hover:text-white transition-colors duration-300"
            >
              Impressum
            </a>
            <a
              href="#"
              className="text-mint-300 hover:text-white transition-colors duration-300"
            >
              Datenschutz
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;