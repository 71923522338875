import React from 'react';

const ContactForm = () => {
  return (
    <section id="contact" className="py-24 bg-mint-900 text-white">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-serif mb-12 text-center">Kontaktieren Sie uns</h2>
        <form className="max-w-lg mx-auto bg-white p-8 rounded-lg shadow-xl">
          <div className="mb-6">
            <label htmlFor="name" className="block text-mint-800 mb-2 font-semibold">Name</label>
            <input
              type="text"
              id="name"
              className="w-full px-4 py-3 border border-mint-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-mint-500 transition-all duration-300"
              required
            />
          </div>
          <div className="mb-6">
            <label htmlFor="email" className="block text-mint-800 mb-2 font-semibold">Email</label>
            <input
              type="email"
              id="email"
              className="w-full px-4 py-3 border border-mint-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-mint-500 transition-all duration-300"
              required
            />
          </div>
          <div className="mb-6">
            <label htmlFor="message" className="block text-mint-800 mb-2 font-semibold">Nachricht</label>
            <textarea
              id="message"
              rows={4}
              className="w-full px-4 py-3 border border-mint-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-mint-500 transition-all duration-300"
              required
            ></textarea>
          </div>
          <button
            type="submit"
            className="w-full bg-mint-500 text-white px-6 py-3 rounded-full hover:bg-mint-600 transition-colors duration-300 transform hover:scale-105"
          >
            Nachricht senden
          </button>
        </form>
      </div>
    </section>
  );
};

export default ContactForm;