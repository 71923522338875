import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import QuiltsPage from './pages/QuiltsPage';
import ContactPage from './pages/ContactPage';

const App = () => {
  return (
    <Router>
      <div className="bg-gradient-to-br from-mint-50 to-mint-100 min-h-screen font-sans text-mint-900">
        <Header />
        <main>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/quilts" element={<QuiltsPage />} />
            <Route path="/contact" element={<ContactPage />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;