import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Menu, X } from 'lucide-react';

const menuItems = [
  { name: 'Über Beatrix', route: '/about' },
  { name: 'Quilts', route: '/quilts' },
  { name: 'Kontakt', route: '/contact' },
];

const Header = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isHeaderSticky, setIsHeaderSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsHeaderSticky(window.scrollY > 0);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <header
      className={`py-4 transition-all duration-300 ${
        isHeaderSticky
          ? 'fixed top-0 left-0 right-0 bg-mint-100 shadow-md z-50'
          : ''
      }`}
    >
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center">
          <Link to="/" className="text-3xl font-serif font-bold">
            <span className="text-mint-600">L</span>appeteppe
          </Link>
          <nav className="hidden md:flex space-x-6">
            {menuItems.map((item) => (
              <Link
                key={item.route}
                to={item.route}
                className="text-mint-800 hover:text-mint-600 transition-colors duration-300"
              >
                {item.name}
              </Link>
            ))}
          </nav>
          <button
            className="md:hidden text-mint-800"
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
          >
            {mobileMenuOpen ? <X /> : <Menu />}
          </button>
        </div>
        {mobileMenuOpen && (
          <nav className="mt-4 space-y-2">
            {menuItems.map((item) => (
              <Link
                key={item.route}
                to={item.route}
                className="block py-2 text-mint-800 hover:text-mint-600 transition-colors duration-300"
                onClick={() => setMobileMenuOpen(false)}
              >
                {item.name}
              </Link>
            ))}
          </nav>
        )}
      </div>
    </header>
  );
};

export default Header;